<template>
  <div id="app">
    <headJump :routerList="routerList"></headJump>
    <!-- 底部 图片-->
    <div
      class="finish-buttom"
      :style="{ backgroundImage: 'url(' + finishImg + ')' }"
    >
      <div class="finish-title">立即体验 快速提升销售业绩</div>
      <div class="flex-scan">
        <img
          class="flex-scan scan"
          style="margin-top: 0.5rem"
          src="../src/assets/lianxierweima.png"
        />
      </div>
    </div>
    <!-- 底部 -->
    <div
      class="last-buttom"
      :class="equipment ? 'flex-scan' : ''"
      :style="{
        height: equipment ? '1.7rem' : '4.7rem',
        'padding-top': equipment ? '' : '0.5rem',
      }"
    >
      <div>
        <div class="flex-scan">
          <img class="last-img" src="../src/assets/lianxierweima.png" />
        </div>
        <div class="last-img-title">企业微信联系</div>
      </div>
      <div
        class="margin-link-size"
        :class="equipment ? '' : 'flex-scan'"
        :style="{ 'padding-top': equipment ? '' : '0.3rem' }"
      >
        <div>
          <div class="link-size" :class="equipment ? '' : 'flex-scan'">
            <div class="link-size-right">网站首页</div>
            <div class="link-size-right">产品</div>
            <div class="link-size-right">价格</div>
            <div class="link-size-right">关于乘单</div>
          </div>
          <div
            class="message"
            :style="{ 'margin-left': equipment ? '' : '1rem' }"
          >
            <div
              class="message-buttom"
              :class="equipment ? 'message-flex' : ''"
            >
              <div style="margin-right: 0.4rem">邮编：51458</div>
              <div style="margin-right: 0.4rem">
                邮箱：chengdan@chengdan.plus
              </div>
              <div>电话：18922462869</div>
            </div>
            <div class="message-buttom">
              版权所有：广东乘单智能科技有限公司
              <span class="message-pad-left">
                <a :href="'https://beian.miit.gov.cn/'">粤ICP备2021017861号</a>
              </span>
            </div>
            <div class="message-buttom">
              地址：广州市南沙区双山大道越秀国际广场1栋1202
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      //顶部页面跳转数组
      routerList: [
        {
          id: 0,
          name: "首页",
          link: "/",
          isClick: true,
        },
        {
          id: 1,
          name: "产品解决方案",
          link: "/Solution",
          isClick: false,
        },
        {
          id: 2,
          name: "关于我们",
          link: "/AboutUS",
          isClick: false,
        },
      ],
      //底部图片
      finishImg: require("./assets/tableBtn/zu15.png"),
      //监听是否是pc端还是移动端
      equipment: true,
    };
  },
  created() {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.equipment = flag ? false : true;
    });
  },
};
</script>
<style>
body {
  margin: 0rem !important;
}
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  a:-webkit-any-link {
    text-decoration: none;
  }

  body: {
    background-color: #f4f9ff;
  }

  .box_big {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .el-button {
    padding: 10px 25px;
  }

  .message-pad-left {
    padding-left: 0.1rem;
  }
}
</style>