var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solution"},[_c('div',{staticClass:"solution1"},[_c('div',{staticClass:"outer-box-flex"},[_vm._m(0),_c('div',{staticClass:"box outer-box-flex"},[_c('img',{style:({'height':_vm.equipment ? '' : '1.7rem'}),attrs:{"src":require("../assets/lianxierweima.png")}})])]),_c('div',{staticClass:"functional",style:({'justify-content':_vm.equipment? '':'space-evenly',
    'align-items':_vm.equipment ? '' : 'center','flex-direction':_vm.equipment ? '' : 'column',
    'margin-top': _vm.equipment ? '0.12rem' : '0.2rem'})},_vm._l((_vm.functionalList),function(item,index){return _c('div',{key:index,staticClass:"functional-device",style:({'margin-right': index != 3 && _vm.equipment ? '0.2rem' : '','margin-top':_vm.equipment ? '':'0.2rem',
      'height': _vm.equipment ? '1.6rem' : ''})},[_c('div',{style:({'padding': _vm.equipment ? '0rem 0.22rem 0rem 0.2rem':'0rem 0.3rem 0rem 0.5rem',
        'width':_vm.equipment?'1rem':'3rem'})},[_c('div',{staticClass:"functional-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"functional-center"},[_vm._v(_vm._s(item.center))])]),_c('div',{staticClass:"functional-border"}),_c('div',{staticClass:"functional-img-border"},[_c('img',{style:({'height':_vm.equipment ? '0.25rem' : '0.5rem','width':_vm.equipment ? '0.25rem' : '0.5rem'}),attrs:{"src":item.img}})])])}),0)]),_vm._l((_vm.marketList),function(item1,index1){return _c('div',{key:index1,style:({'margin-top': index1 == 0 ? '10%' : '12%'})},[_c('div',{staticClass:"market"},[_c('div',{staticClass:"introduce"},[_vm._v(_vm._s(item1.title))]),_c('div',{staticClass:"market-center"},[_vm._v(_vm._s(item1.center))])]),_c('div',{staticClass:"acea-row row-center-wrapper",staticStyle:{"margin-top":"3%"}},[_c('div',{staticClass:"acea-row row-between row-middle section-two-box"},[(index1 == 1)?_c('div',{staticClass:"market-img",style:({'width':index1 != 1 ?'3rem':'4rem',backgroundImage: 'url(' + item1.url + ')',
          'margin-right':index1 == 1 ? '0.3rem' : ''})}):_vm._e(),_c('div',{staticClass:"acea-row row-column row-top row-between",staticStyle:{"text-align":"left","width":"3.5rem"},style:({'height': _vm.equipment? '3.5rem' : ''})},_vm._l((item1.itemList),function(item,index){return _c('div',{key:index},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"market-icon",style:({ backgroundImage: 'url(' + item.icon + ')' ,'margin-top':index1===2 && index == 1 ? '0rem':index1===2 && index != 1 ? '0.1rem' :'0.01rem',
                'margin-bottom':index1===2 && index == 1 ? '0.1rem':' 0rem'})}),_c('div',{style:({'margin-left':index1 !== 2 ? '' : '0.07rem'})},[_c('div',{staticClass:"market-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"market-text"},[_vm._v(_vm._s(item.text))])])])])}),0),(index1 != 1)?_c('div',{staticClass:"market-img",style:({'width':index1 != 1 ?'3rem':'4rem',backgroundImage: 'url(' + item1.url + ')',
          'margin-left':index1 != 1 ? '0.5rem':''})}):_vm._e()])])])}),_c('flex-imgs',{attrs:{"title":'丰富使用场景',"conter":'让每一个触客渠道都成为获客渠道',"imgList":_vm.imgList}}),_c('buttons',{attrs:{"suspensionList":_vm.suspensionList}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-box-flex"},[_c('div',{staticClass:"title"},[_vm._v("乘单电子名片")]),_c('div',{staticClass:"annotation"},[_vm._v("沉淀私域流量提升运营能力")]),_c('img',{staticClass:"bivector outer-box-flex",attrs:{"src":require("../assets/lianxierweima.png")}})])}]

export { render, staticRenderFns }