<template>
  <div class="solution">
    <div class="solution1">
      <div class="outer-box-flex">
        <div class="inner-box-flex">
          <div class="title">乘单电子名片</div>
          <div class="annotation">沉淀私域流量提升运营能力</div>
          <!-- <div>二维码</div> -->
          <img class="bivector outer-box-flex" src="../assets/lianxierweima.png" />
        </div>
        <div class="box outer-box-flex">
          <img :style="{'height':equipment ? '' : '1.7rem'}" src="../assets/lianxierweima.png" />
        </div>
      </div>
      <div
        class="functional"
        :style="{'justify-content':equipment? '':'space-evenly',
      'align-items':equipment ? '' : 'center','flex-direction':equipment ? '' : 'column',
      'margin-top': equipment ? '0.12rem' : '0.2rem'}"
      >
        <div
          class="functional-device"
          v-for="(item,index) in functionalList"
          :key="index"
          :style="{'margin-right': index != 3 && equipment ? '0.2rem' : '','margin-top':equipment ? '':'0.2rem',
        'height': equipment ? '1.6rem' : ''}"
        >
          <div
            :style="{'padding': equipment ? '0rem 0.22rem 0rem 0.2rem':'0rem 0.3rem 0rem 0.5rem',
          'width':equipment?'1rem':'3rem'}"
          >
            <div class="functional-title">{{item.title}}</div>
            <div class="functional-center">{{item.center}}</div>
          </div>
          <div class="functional-border"></div>
          <div class="functional-img-border">
            <img
              :style="{'height':equipment ? '0.25rem' : '0.5rem','width':equipment ? '0.25rem' : '0.5rem'}"
              :src="item.img"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 智能销售名片 -->
    <div
      v-for="(item1, index1) in marketList"
      :key="index1"
      :style="{'margin-top': index1 == 0 ? '10%' : '12%'}"
    >
      <div class="market">
        <div class="introduce">{{item1.title}}</div>
        <div class="market-center">{{item1.center}}</div>
      </div>
      <div class="acea-row row-center-wrapper" style="margin-top:3%">
        <div class="acea-row row-between row-middle section-two-box">
          <div
            v-if="index1 == 1"
            class="market-img"
            :style="{'width':index1 != 1 ?'3rem':'4rem',backgroundImage: 'url(' + item1.url + ')',
            'margin-right':index1 == 1 ? '0.3rem' : ''}"
          ></div>
          <div
            class="acea-row row-column row-top row-between"
            :style="{'height': equipment? '3.5rem' : ''}"
            style="text-align: left;width: 3.5rem;"
          >
            <div v-for="(item, index) in item1.itemList" :key="index">
              <div style="display:flex">
                <div
                  class="market-icon"
                  :style="{ backgroundImage: 'url(' + item.icon + ')' ,'margin-top':index1===2 && index == 1 ? '0rem':index1===2 && index != 1 ? '0.1rem' :'0.01rem',
                  'margin-bottom':index1===2 && index == 1 ? '0.1rem':' 0rem'}"
                ></div>
                <div :style="{'margin-left':index1 !== 2 ? '' : '0.07rem'}">
                  <div class="market-title">{{ item.title }}</div>
                  <div class="market-text">{{ item.text }}</div>
                </div>
              </div>
            </div>
            <!-- <div
              class="button"
              :style="{'height': equipment?'0.3rem':'0.4rem','line-height': equipment?'0.3rem':'0.4rem',
            'width': equipment ? '25%' : '40%'}"
              @click="goTo()"
            >免费试用</div>-->
          </div>
          <div
            v-if="index1 != 1"
            class="market-img"
            :style="{'width':index1 != 1 ?'3rem':'4rem',backgroundImage: 'url(' + item1.url + ')',
            'margin-left':index1 != 1 ? '0.5rem':''}"
          ></div>
        </div>
      </div>
    </div>
    <!-- 丰富使用场景 -->
    <flex-imgs :title="'丰富使用场景'" :conter="'让每一个触客渠道都成为获客渠道'" :imgList="imgList"></flex-imgs>
    <buttons :suspensionList="suspensionList"></buttons>
  </div>
</template>


<script>
// @ is an alias to /src
export default {
  name: 'Solution',
  data () {
    return {
      // 乘单电子名片
      functionalList: [
        {
          title: '营销获客',
          center: '内容传播精准获客',
          img: require('../assets/yingxiaohuoke.png')
        },
        {
          title: '智能客户推荐',
          center: '内容传播精准获客',
          img: require('../assets/xiaoshougenjin (1).png')
        }, {
          title: '销售跟进',
          center: '内容传播精准获客',
          img: require('../assets/xiaoshou  (1).png')
        }
        , {
          title: '社交分享',
          center: '内容传播精准获客',
          img: require('../assets/shejiaofenxiang (1).png')
        }
      ],
      // 智能销售名片
      marketList: [
        {
          url: require("../assets/tableBtn/zhinengxiaoshoumingpian.png"),
          title: '智能销售名片',
          center: '全面展示企业及个人信息',
          itemList: [
            {
              title: "打造员工个人IP ",
              text: "自定义名片内容，全方位u规范化展示个人信息与介绍，提升客户信任度",
              icon: require("../assets/tableBtn/pingtai.png"),
            },
            {
              title: "企业信息展示 ",
              text: "企业详情介绍，图文并茂，连接官网，让客户更了解",
              icon: require("../assets/tableBtn/yingyong.png"),
            },
            {
              title: "连接商城 沉淀私域流量",
              text: "千人千店，千店千面：个性化匹配终端需求，提升客户信任度，提升客户信任度",
              icon: require("../assets/tableBtn/youxi.png"),
            },
            {
              title: "商务雷达",
              text: "海量精准标签客户，大数据优化商业决策力配终端需求",
              icon: require("../assets/tableBtn/leida.png"),
            },
          ],
        },
        {
          url: require("../assets/tableBtn/duotujin.png"),
          title: '多途径获客',
          center: '多形式内容传播，带来销售线索并转换',
          itemList: [
            {
              title: "一键分享个人名片",
              text: "内容分享自带个人名片，带微信群发，朋友转转发名片多种形式",
              icon: require("../assets/tableBtn/mingpian.png"),
            },
            {
              title: "红包推广获客",
              text: "可在各种场所推广红包，可指定新老客户、标签等，效果有分析",
              icon: require("../assets/tableBtn/hongbao.png"),
            },
            {
              title: "海量海报一键生成",
              text: "动态、节日祝福等一键生成海报，分享转发即可获客",
              icon: require("../assets/tableBtn/tupian.png"),
            },
            {
              title: "热点资讯更新",
              text: "行业资讯、网络热点、公司咨询更新，提供优质社交互动内容",
              icon: require("../assets/tableBtn/zixun.png"),
            },
          ],
        },
        {
          url: require("../assets/tableBtn/chengjiaojine.png"),
          title: '智能预测成交金额',
          center: '距离目标还需拓展多少新客户',
          itemList: [
            {},
            {
              title: "标注客户拜访进度与阶段",
              text: "",
              icon: require("../assets/tableBtn/jinduzhuizong.png"),
            },
            {
              title: "通过对销售管线的分析可以动态反映销售机会的升迁状态，预测销售结果",
              text: "",
              icon: require("../assets/tableBtn/xiaoshoubaobiao.png"),
            },
            {
              title: "评估销售人员和销售团队的销售能力，发现销售过程的障碍和瓶颈",
              text: "",
              icon: require("../assets/tableBtn/zhangai.png"),
            }
          ],
        }
      ],
      // 丰富使用场景
      imgList: [
        {
          itemList: [
            {
              img: require('../assets/tableBtn/xuanchuanshouce.png')
            },
            {
              img: require('../assets/tableBtn/zizhimingpian.png')
            },
            {
              img: require('../assets/tableBtn/xianxiahuodong.png')
            },
          ],
        },
        {
          itemList: [
            {
              img: require('../assets/tableBtn/huwaiguanggao.png')
            },
            {
              img: require('../assets/tableBtn/xianshangguanggao.png')
            },
            {
              img: require('../assets/tableBtn/qiyeguanwang.png')
            }
          ]
        }
      ],
      //监听是否是pc端还是移动端
      equipment: true,
      //返回顶部
      suspensionList: []
    }
  },
  created () {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.equipment = flag ? false : true;
    })
  },
  mounted: function () {
    window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    // 显示回到顶部按钮
    showbtn () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 1 && this.suspensionList.length == 0) {
        this.suspensionList.push({
          url: require("../assets/tableBtn/huojian.png"),
          title: "返回顶部"
        })
      } else if (scrollTop === 0) {
        this.suspensionList.pop();
      }
    },
    goTo () {
      window.open("https://mall.qinlin.plus");
    },
  }
}
</script>
<style scoped>
</style>
