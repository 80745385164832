import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './components/index'
//引入通用样式
import './assets/css/common.scss'
import "./assets/css/base.css";
import "./assets/css/animate.css";
import './assets/css/solution.scss'
import './assets/css/headJump.scss'
import './assets/css/home.scss'
import './assets/css/solution.scss'
import './assets/css/aboutUS.scss'
import "./assets/js/media_750";
// 没适配项目页面，请访问https://juejin.cn/post/7033663874859532318/
import 'lib-flexible'
import {
  api
} from './axios/api'
// 引入饿了么的组件 npm install element-ui -S
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
Vue.prototype.$api = api;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')