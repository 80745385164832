<template>
  <div>
    <div class="market" style="margin:10% 0% 5% 0%">
      <div class="introduce">{{title}}</div>
      <div class="market-center">{{conter}}</div>
    </div>
    <div class="flex-img" v-for="(item,index) in imgList" :key="index">
      <div v-for="(item1,index1) in item.itemList" :key="index1">
        <img class="img-item" :src="item1.img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'flexImgs',
  props: {
    title: {
      type: String
    },
    conter: {
      type: String
    },
    imgList: {
      type: Array,
      default: []
    }
  }
}
</script>
<style>
.img-item {
  padding: 0.05rem;
  width: 2.2rem;
  height: 1.5rem;
}
</style>