<template>
  <div>
    <div class="headJump">
      <!-- 公司Logo/名称 -->
      <div class="headJump" :style="{'margin' : equipment?'0.12rem 0rem':'0.3rem 0rem'}">
        <img
          class="margin-right"
          alt="Vue logo"
          :style="{'width': equipment ? '0.25rem' : '0.45rem','height': equipment ? '0.2rem' : '0.45rem'}"
          src="../../assets/logo.png"
        />
        <div class="size-color size-weight">乘单智能</div>
      </div>
      <!-- 页面跳转 -->
      <div class="headJump link" :style="{'margin': equipment ? '0rem 7%' : ''}">
        <router-link
          v-for="(item, index) in routerList"
          :key="index"
          :to="item.link"
          @click.native="clickLink(item.id)"
          style="text-decoration: none"
        >
          <div class="margin-right-left" :class="item.isClick ? 'size-color' : ''">{{ item.name }}</div>
        </router-link>
      </div>
      <!-- 登录/注册 -->
      <!-- <div class="button1 button-border">登录注册</div> -->
      <!-- 按钮 - 跳转到亲邻网后台 -->
      <!-- <div class="button2 button-border" @click="goTo()">免费试用</div> -->
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "headJump",
  props: {
    // 页面名,页面跳转
    routerList: {
      type: Array,
      default: {},
    },
  },
  data () {
    return {
      equipment: true
    };
  },
  watch: {
    '$route' (to, from) { //监听路由是否变化
      if (to.path === '/Solution') {
        this.routePath(1);
      } else if (to.path === '/AboutUS') {
        this.routePath(2);
      } else {
        this.routePath(0);
      }
    },
  },
  created () {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.equipment = flag ? false : true;
      if (this.$route.path === '/Solution') {
        this.routePath(1);
      } else if (this.$route.path === '/AboutUS') {
        this.routePath(2);
      }
    })
  },
  methods: {
    //点击后改变字体颜色
    clickLink (id) {
      this.routerList.forEach((v) => (v.isClick = v.id === id ? true : false));
    },
    //根据路由高亮字体颜色
    routePath (index) {
      this.routerList.forEach((v, i) => (v.isClick = i == index ? true : false));
    },
    goTo () {
      window.open("https://mall.qinlin.plus");
    },
  },
};
</script>
<style lang="scss" scoped>
.headJump {
  // padding: 10px 0px;
  a {
    color: #2c3e50;
  }
  .login {
    border: 1px solid #409eff;
    color: #409eff;
  }
}
</style>
