<template>
  <div class="home">
    <div>
      <div
        :style="{ backgroundImage: 'url(' + url + ')' }"
        class="head acea-row row-center-wrapper headerbg"
      >
        <div class="heder-setion2">
          <div class="big_font_title">智能推荐客户的销售名片小程序</div>
          <div class="acea-row row-between" style="margin-top: 3%">
            <div class="acea-row row-column-between box-tit-btn">
              <div class="small_title">沉淀私域流量提升运营能力</div>
              <!-- <el-button style="width: 100px" type="primary" @click="goTo()"
              >免费试用</el-button
              >-->
              <!-- <div
                class="button"
                :style="{'height':  equipment?'0.3rem':'0.4rem','line-height':  equipment?'0.3rem':'0.4rem'}"
                @click="goTo()"
              >免费试用</div>-->
            </div>
            <div
              style="margin-right:6%"
              class="acea-row row-column-between"
              :class="equipment ? 'erweima-box' : ''"
              :style="{'height':equipment?'':'1rem','width':equipment?'':'1rem'}"
            >
              <img :style="{'height':equipment ? '' : '1.5rem'}" src="../assets/lianxierweima.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 第二部分 -->
      <div class="title acea-row row-center-wrapper">
        <div class="acea-row row-column-between section-two-box">
          <div class="introduce">从营销获客，到线索转化</div>
          <div class="textEdit">洞察客户行为，高效跟进客户，赋能企业数字化能力</div>
        </div>
      </div>
      <!-- 第二部分 END-->

      <!-- 第三部分 -->
      <div class="acea-row row-center-wrapper">
        <div class="title-center row-center acea-row row-middle">
          <div
            v-for="(item, index) in btnList"
            :key="index"
            class="title-center-padding btnTextItem"
          >
            <div class="acea-row row-middle">
              <div class="border-padding">
                <div
                  class="border-background"
                  :style="{ backgroundImage: 'url(' + item.icon + ')' }"
                ></div>
              </div>
              <div class="border-title">{{ item.title }}</div>
            </div>
            <div class="border-text">{{ item.teatx }}</div>
          </div>
        </div>
      </div>

      <!-- 第四部分 -->
      <div class="acea-row row-center-wrapper">
        <div style="margin: 10% 0% 0% 0%" class="acea-row row-column-between section-two-box">
          <div class="introduce">销售过程和营销活动管理</div>
          <div class="textEdit">智能销售实现业绩增长</div>
        </div>
      </div>
      <!-- 第四部分 END-->

      <!-- 第五部分 -->
      <div class="acea-row row-center-wrapper">
        <div
          style="margin: 5% 0% 10%;width: 7.1rem;"
          class="acea-row row-between row-middle section-two-box"
        >
          <div class="market" :style="{backgroundImage: 'url(' + url + ')'}"></div>
          <div
            class="acea-row row-column row-top row-between"
            :style="{'height': equipment? '2.7rem' : ''}"
            style="text-align: left;width: 3.5rem;"
          >
            <div class="acea-row row-top" v-for="(item, index) in marketingCopyList" :key="index">
              <div class="flex">
                <div class="market-icon" :style="{ backgroundImage: 'url(' + item.icon + ')' }"></div>
                <div style="font-size: 0.12rem">{{ item.title }}</div>
              </div>
              <div class="row-top row-left">
                <div class="market-text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第五部分 END-->
      <div class="acea-row row-center-wrapper">
        <div style="margin: 5% 0%" class="acea-row row-column-between section-two-box">
          <div class="introduce">特色产品服务</div>
          <div class="textEdit">提升您的数字化运营能力</div>
        </div>
      </div>
      <div style="margin-bottom:12%">
        <div class="feature-border">
          <div
            v-for="(item,index) in featureList"
            :key="index"
            class="feature-center"
            :style="{'padding': index != 2 ? '0.3rem' : '0.3rem 0.15rem 0.3rem 0.2rem',
            'border-left': index == 0 ?'0.01rem solid #EEF1F8':''}"
          >
            <img class="feature-icon" :src="item.icon" />
            <div class="text-left">
              <div class="feature-title">{{item.title}}</div>
              <div
                v-for="(item1,index1) in item.contentList"
                :key="index1"
                class="title-warpper"
              >{{item1.content}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 零售数据化 -->
      <div>
        <img style="width:100%;margin-bottom:4%" src="../assets/linshoushujuhua.png" />
      </div>
      <!-- 完善的服务与技术支持 -->
      <div class="acea-row row-center-wrapper">
        <div style="margin: 6% 0%" class="acea-row row-column-between section-two-box">
          <div class="introduce">完善的服务与技术支持</div>
          <div class="textEdit">来自互联网优秀的技术研发团队，打造安全稳定可用的系统</div>
        </div>
      </div>
      <div
        class="business-card"
        :style="{'justify-content':equipment? 'center':'space-evenly',
      'align-items':equipment ? '' : 'center','flex-direction':equipment ? '' : 'column'}"
      >
        <div
          v-for="(item,index) in cardList"
          :key="index"
          class="business-card-warpper"
          :style="{ backgroundImage: 'url(' + item.backgroundImg + ')' ,'width':equipment?'2.4rem': '5rem',
    'height' :equipment?'1.8rem': '3rem'}"
        >
          <div
            class="business-card-titie"
            :style="{'padding':equipment?'0.4rem 0rem 0rem 0.4rem': '0.4rem 0rem 0rem 0.7rem'}"
          >{{item.title}}</div>
          <div :style="{'margin-left':equipment?'0.4rem':'0.8rem'}" class="business-card-border"></div>
          <div
            class="business-card-center"
            :style="{'width':equipment?'1.7rem': '3.7rem','padding':equipment?'0.2rem 0rem 0rem 0.4rem': '0.1rem 0rem 0rem 0.7rem'}"
          >
            <div>{{item.center1}}</div>
            <div>{{item.center2}}</div>
            <div>{{item.center3}}</div>
          </div>
        </div>
      </div>
    </div>
    <buttons :suspensionList="suspensionList"></buttons>
  </div>
</template>


<script>
// @ is an alias to /src
export default {
  name: "Home",
  data () {
    return {
      url: require("../assets/banner.png"),
      //完善的服务与技术支持
      cardList: [
        {
          title: '专属客户成功经理',
          backgroundImg: require('../assets/kehujinli.png'),
          center1: '配备专属客户成功经理',
          center2: '提供从内部推广到上线使用的客户成功服务确保项目顺利落地',
          center3: ''
        },
        {
          title: '7x12小时',
          backgroundImg: require('../assets/7＊12.png'),
          center1: '7×12小时服务支持',
          center2: '',
          center3: ''
        },
        {
          title: '产品快速迭代',
          backgroundImg: require('../assets/产品快速迭代.png'),
          center1: '每周一次的产品迭代',
          center2: '两周需求实现的速度',
          center3: '让你的需求快速变为现实'
        }
      ],
      //特色产品服务
      featureList: [
        {
          icon: require('../assets/liebian.png'),
          title: '拉新裂变获客',
          contentList: [
            {
              content: '名片裂变'
            },
            {
              content: '海报裂变'
            },
            {
              content: '红包裂变'
            },
            {
              content: '营销活动'
            },
            {
              content: '商城引流'
            },
            {
              content: '优惠券引流'
            },
            {
              content: '直播裂变'
            },
            {
              content: '附近客户'
            }
          ]
        },
        {
          icon: require('../assets/qiye.png'),
          title: '企业办公体系',
          contentList: [
            {
              content: '海量客户列表'
            },
            {
              content: '任务管理'
            },
            {
              content: '企业管理'
            },
            {
              content: '宣传册'
            },
            {
              content: '企业动态'
            },
            {
              content: '案例库'
            },
            {
              content: '群发消息'
            },
            {
              content: '调查问卷'
            }
          ]
        }, {
          icon: require('../assets/zhinengshujufenxi.png'),
          title: '智能数据分析',
          contentList: [
            {
              content: '销售业绩'
            },
            {
              content: '订单分析'
            },
            {
              content: '成交趋势'
            },
            {
              content: '销售漏斗'
            },
            {
              content: '目标管理'
            },
            {
              content: '客户跟进'
            },
            {
              content: '自定义单据-统计报表'
            },
            {
              content: '线索转换分析'
            }
          ]
        }, {
          icon: require('../assets/yingxiaohuodongguanli.png'),
          title: '营销活动管理',
          contentList: [
            {
              content: '拓客活动管理'
            },
            {
              content: '全员管理'
            },
            {
              content: '业务单据'
            },
            {
              content: '联系人管理'
            },
            {
              content: '红包管理'
            },
            {
              content: '竞品管理'
            },
            {
              content: '订单管理'
            },
            {
              content: '积分兑换'
            }
          ]
        }, {
          icon: require('../assets/zhinengxiaoshougongju.png'),
          title: '智能销售工具',
          contentList: [
            {
              content: '名片群发'
            },
            {
              content: '素材库'
            },
            {
              content: '客户练习'
            },
            {
              content: '任务提醒'
            },
            {
              content: '销售资料库'
            },
            {
              content: '表单流程'
            },
            {
              content: '培训学习'
            },
            {
              content: '精细寻找客户'
            }
          ]
        }
      ],
      btnList: [
        {
          title: "产品推广",
          icon: require("../assets/tableBtn/btn8.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "官网宣传",
          icon: require("../assets/tableBtn/btn3.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "智能名片",
          icon: require("../assets/tableBtn/btn4.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "商务雷达",
          icon: require("../assets/tableBtn/btn1.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "微信互通",
          icon: require("../assets/tableBtn/btn5.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "智能提醒",
          icon: require("../assets/tableBtn/btn2.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "线索切换",
          icon: require("../assets/tableBtn/btn7.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
        {
          title: "小程序沟通",
          icon: require("../assets/tableBtn/btn6.png"),
          teatx: "输入副标题输入副标题输入副标",
        },
      ],

      marketingCopyList: [
        {
          title: "精细化标签管理客户 ",
          text:
            "可按区域、行业、产品类别、部门来划分客户的公海池，分公海管理客户 ",
          icon: require("../assets/yinxiaoIcon.png"),
        },
        {
          title: "智能评估客户价值 ",
          text:
            "企业根据客户智能分级，自定义评定标准，根据自己划分的等级，自动评估客户等级",
          icon: require("../assets/yinxiaoIcon.png"),
        },
        {
          title: "全员营销——BBC（全渠道参与） ",
          text: "",
          icon: require("../assets/yinxiaoIcon.png"),
        },
        {
          title: "全员营销——人人都有自己系统 ",
          text:
            "以单个员工为最小单位的推广渠道，以线下线上全渠道作为来源，实现流程可追溯、效果可量化，业绩可视化的营销场景，让每个员工的所有业绩都透明化。",
          icon: require("../assets/yinxiaoIcon.png"),
        },
        {
          title: "分销商城 ",
          text: "",
          icon: require("../assets/yinxiaoIcon.png"),
        },
      ],
      //监听是否是pc端还是移动端
      equipment: true,
      //按钮数组
      suspensionList: [
        {
          url: require("../assets/tableBtn/keguIcon.png"),
          title: "在线咨询"
        },
        {
          url: require("../assets/tableBtn/erweimaIcon.png"),
          title: "免费试用"
        }
      ]
    };
  },
  created () {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.equipment = flag ? false : true;
    })
  },
  mounted: function () {
    window.addEventListener("scroll", this.showbtn, true);
    // window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    //打开亲邻网后台
    goTo () {
      window.open("https://mall.qinlin.plus");
    },
    // 显示回到顶部按钮
    showbtn () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 1 && this.suspensionList.length == 2) {
        this.suspensionList.push({
          url: require("../assets/tableBtn/huojian.png"),
          title: "返回顶部"
        })
      } else if (scrollTop === 0 && this.suspensionList.length == 3) {
        this.suspensionList.pop();
      }
    },
    handleScroll () {
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 设备/屏幕高度
      let scrollObj = document.getElementById("riduis_Btn_box"); // 滚动区域
      let scrollTop = scrollObj.scrollTop; // div 到头部的距离
      let scrollHeight = scrollObj.scrollHeight; // 滚动条的总高度
      console.log(
        "滚动事件",
        scrollObj.scrollHeight,
        scrollHeight,
        scrollTop + clientHeight
      );
      //滚动条到底部的条件
      if (scrollTop + clientHeight == scrollHeight) {
        // div 到头部的距离 + 屏幕高度 = 可滚动的总高度
        console.log("可滚动事件");
      }
    },
  }
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
}
.float-button {
  position: fixed;
  width: 40px;
  right: 50px;
  bottom: 50px;
}
.heder-setion2 {
  width: 54%;
  margin: 3% 0% 10% -3%;
}
.small_title {
  color: white;
  font-size: 0.12rem;
}
.big_font_title {
  font-size: 0.4rem;
  color: white;
  padding-top: 5%;
  text-align: left;
}
.btnTextItem:hover {
  animation: pulse 3s;
}
.erweima-box {
  height: 1rem;
  width: 1rem;
}
.erweima-box .title {
  color: #3c4353;
  margin-top: 0.1rem;
  font-size: 0.1rem;
}
.section-two-box {
  margin-top: 8%;
}
.section-two-box .titlebig {
  color: #3c4353;
  font-size: 0.22rem;
  margin-bottom: 0.06rem;
}
.section-two-box .textEdit {
  color: #838a9d;
  font-size: 0.1rem;
  margin-top: 0.04rem;
}
.section-three-box {
  margin-top: 15%;
  margin-bottom: 8%;
}
</style>
