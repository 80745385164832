<template>
  <!-- 悬浮按钮 -->
  <div
    :style="{'margin-right': equipment? '8%' : ''}"
    class="float-button acea-row row-column row-between"
    id="riduis_Btn_box"
  >
    <div
      class="suspension-button acea-row row-center-wrapper"
      :style="{'width': equipment? '0.5rem' : '1.8rem','height': equipment? '0.5rem' : '1.8rem','margin-top':equipment ? '0.15rem':'0.2rem'}"
      v-for="(item,index) in suspensionList"
      :key="index"
      @click="backtop(index)"
    >
      <div
        :style="{ backgroundImage: 'url(' + item.url + ')','width': equipment? '0.3rem' : '0.8rem','height': equipment? '0.3rem' : '0.8rem' }"
        style="background-size: 100% 100%"
      ></div>
      <div style="color: #148de3; font-size: 0.08rem;margin-top: -10%">{{ item.title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'buttons',
  props: {
    //按钮数组
    suspensionList: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      //监听是否是pc端还是移动端
      equipment: true
    }
  },
  created () {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.equipment = flag ? false : true;
    })
  },
  methods: {
    /**
      * 回到顶部功能实现过程：
      * 1. 获取页面当前距离顶部的滚动距离（虽然IE不常用了，但还是需要考虑一下兼容性的）
      * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
      * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
      * 4. 最后记得在移动到顶部时，清除定时器
    */
    backtop (index) {
      if (index == 2 && this.suspensionList.length == 3 || index == 0 && this.suspensionList.length == 1) {
        var timer = setInterval(function () {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5);
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          this.isTop = true;
          if (osTop === 0) {
            clearInterval(timer);
          }
        }, 30)
      }
    },
  }
}
</script>
<style scoped>
.float-button {
  position: fixed;
  width: 40px;
  right: 50px;
  bottom: 50px;
}
</style>

