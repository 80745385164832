<template>
  <div class="AboutUS">
    <div class="introduces">
      <div style="padding-bottom: 0.4rem">
        <div class="introduce">乘单公司介绍</div>
        <div class="team">专业团队 优质服务</div>
      </div>
      <div class="flexs" style="margin:0rem 1.3rem">
        <div style="margin-right:3%;">
          <div class="introduce-size">乘单智能专注于做企业私域流量工具包，现有乘单名片、乘单官网、乘单商城、开新网店等产品。</div>
          <div class="borders" style="margin-top: 0.5rem;">
            <div class="border-title">开新网店</div>
            <div class="border-content">开新网店是是一个线上商城系统，可以用于零售场景、也支持多角色的渠道分销、包含有数百项功能，可帮助客户快速线上化</div>
            <div class="border-img img1">
              <img class="imgs" src="../assets/tableBtn/kaobei.png" />
            </div>
            <!-- <div
              class="button"
              :style="{'height': equipment?'0.25rem':'0.5rem','line-height': equipment?'0.25rem':'0.5rem',
          'width': equipment ? '35%' : '60%'}"
              @click="goTo()"
            >免费试用</div>-->
          </div>
        </div>
        <div class="borders">
          <div class="border-title">乘单名片</div>
          <div class="border-content">乘单名片是销售人员的工具包，也是企业私域流量推广神器，做各位销售的赚钱工具</div>
          <div class="border-img img-flex">
            <img class="imgs2" src="../assets/zhinengxiaoshoumingpian.png" />
          </div>
          <div class="border-content bor-bottom">乘单名片包含有数百项功能和数千万的企业，可帮助客户快速拓客</div>
          <!-- <div
            class="button"
            :style="{'height': equipment?'0.25rem':'0.5rem','line-height': equipment?'0.25rem':'0.5rem',
          'width': equipment ? '35%' : '60%'}"
            @click="goTo()"
          >免费试用</div>-->
        </div>
      </div>
    </div>
    <!-- 我们的优势 -->
    <flex-imgs :title="'我们的优势'" :conter="'六大优势  一站式解决您的问题'" :imgList="imgList"></flex-imgs>
    <div class="market" style="margin:12% 0% 8% 0%">
      <div class="market-title">加入我们</div>
      <div class="market-center">六大优势 一站式解决您的问题</div>
    </div>
    <div
      style="display:flex"
      v-for="(item,index) in joinList"
      :key="index"
      :style="{'margin-top':index !==0 ? '10%':''}"
    >
      <div style="margin-top: 0.25rem;">
        <img class="join-img" :style="{'left' : index%2==1 ? '58%' : ''}" :src="item.img" />
      </div>
      <div>
        <div
          class="join-us"
          :class="index%2==1 ? 'right-padding' : 'left-padding'"
          :style="{'padding-right':index == 0 ? '1rem' : index === 2 ? '0.8rem' : index === 3 ? '7rem':'5rem',
          'margin-right':index==0 || index == 2?'2rem':''}"
        >
          <div class="join-title">{{item.title}}</div>
          <div class="size-weights">{{item.content}}</div>
        </div>
        <div
          :class="index%2==1 ? 'right-paddings' : 'paddings'"
          v-for="(item1,index1) in item.requirementList"
          :key="index1"
        >
          <div class="size-weights">{{item1.title}}</div>
          <div
            class="content-size"
            v-for="(item2,index2) in item1.contentList"
            :key="index2"
          >{{item2.value}}</div>
        </div>
      </div>
    </div>
    <!-- 福利待遇 -->
    <div class="market" style="margin:12% 0% 4% 0%">
      <div class="market-title">福利待遇</div>
    </div>
    <div v-for="(item,index) in imgsList" :key="index">
      <div class="li-img-flex" :style="{}">
        <div>
          <img class="li-icon" :src="item.img" />
        </div>
        <div class="li-size">{{item.content}}</div>
      </div>
    </div>
    <buttons :suspensionList="suspensionList"></buttons>
  </div>
</template>
<script>
export default {
  name: 'AboutUS',
  data () {
    return {
      //返回顶部
      suspensionList: [],
      equipment: true,
      // 我们的优势
      imgList: [
        {
          itemList: [
            {
              img: require('../assets/tableBtn/zhihuixinlingshou.png')
            },
            {
              img: require('../assets/tableBtn/hailiangmianfeikeyuan.png')
            },
            {
              img: require('../assets/tableBtn/dianzimingpian.png')
            },
          ],
        },
        {
          itemList: [
            {
              img: require('../assets/tableBtn/hongbaohuoke.png')
            },
            {
              img: require('../assets/tableBtn/siyuliuliang.png')
            },
            {
              img: require('../assets/tableBtn/1v1fuwu.png')
            }
          ]
        }
      ],
      //加入我们
      joinList: [
        {
          img: require('../assets/tableBtn/tuceng.png'),
          title: '客户经理',
          content: '公司提供客户资料，销售提成15%——20%',
          requirementList: [
            {
              title: '职位描述',
              contentList: [
                {
                  value: '1、年龄18-27岁之间，逻辑思维清晰，有目标追求；'
                },
                {
                  value: '2、打电话或上门拜访意向客户，定期进行回访客户；'
                },
                {
                  value: '3、完成公司安排的其他工作任务；'
                },
                {
                  value: '4、底薪+200电话补助+300红包补助+提成'
                }
              ]
            },
            {
              title: '任职要求',
              contentList: [
                {
                  value: '1、建立和维护客户关系，开发目标客户，跟客户进行深入沟通过程，为客户提供持续性的服务；'
                },
                {
                  value: '2、有电话销售经验或较强网络营销经验；'
                },
                {
                  value: '3、热爱销售行业，有较强的服务意识和销售意识；'
                }
              ]
            }
          ]
        },
        {
          img: require('../assets/yingxiao.png'),
          title: 'SNS营销专员（20）人  综合薪资：4k-8k',
          content: '',
          requirementList: [
            {
              title: '工作内容',
              contentList: [
                {
                  value: '1、【在线做】利用微信/营销QQ/等SNS网络营销方式对公司服务及产品进行宣传营销，成功获取客户认可并注册公司的标准产品【免费服务】，即可收获提成；'
                },
                {
                  value: '2、【提成高】——注册数量即可，如果签约收费版享受高额提成。无需外出陌拜或疯狂打call，线上奔放，线下矜持的你也能做！'
                },
                {
                  value: '3.【Get营销】负责社群的维护及跟进，积极优化及创新推广方式，提升营销效果，学客户沟通心理学。'
                },
              ]
            },
            {
              title: '任职要求',
              contentList: [
                {
                  value: '1、熟悉微信、QQ、各项社群的操作和玩法；'
                },
                {
                  value: '2、有较强的销售目标达成意识及客户服务意识，思维活跃逻辑清晰；'
                },
                {
                  value: '4、熟悉网络销售技巧及客户心态把握，善于通过网络与陌生人沟通聊天；'
                },
                {
                  value: '5、热爱互联网，接受新事物，关注热点事件和话题。'
                }
              ]
            }
          ]
        },
        {
          img: require('../assets/kaifa.png'),
          title: '后端开发工程师',
          content: '有电商软件的开发经验，熟悉电商营销活动的业务流程与开发；',
          requirementList: [
            {
              title: '职位描述',
              contentList: [
                {
                  value: '1、能够按要求、高质量地完成开发或设计任务；'
                },
                {
                  value: '2、掌握常用的分析设计工具，按照规范的要求，编写设计文档；'
                },
                {
                  value: '3、按照设计的功能，实现模块的代码编写和维护；'
                },
                {
                  value: '4、按公司规范进行编码，并对开发的代码进行单元测试；'
                },
                {
                  value: '5、对同事代码进行codereview，能对系统和产品开发提出比较好的建议；'
                },
                {
                  value: '6、能很好的根据需求安排开发计划并跟进。'
                },
              ]
            },
            {
              title: '任职要求',
              contentList: [
                {
                  value: '1、年龄18-27岁之间，逻辑思维清晰，有目标追求；'
                },
                {
                  value: '2、有电话销售经验或较强网络营销经验；'
                },
                {
                  value: '3、热爱销售行业，有较强的服务意识和销售意识；'
                },
              ]
            }
          ]
        },
        {
          img: require('../assets/yingxiao.png'),
          title: '新媒体运营',
          content: '',
          requirementList: [
            {
              title: '岗位职责',
              contentList: [
                {
                  value: '1、运营微信、微博、抖音等新媒体，负责公司的小程序产品乘单名片、商城等的推广运营；'
                },
                {
                  value: '2、研究新媒体的推广模式与渠道，负责制作裂变海报、推广文案、推广视频等;'
                },
                {
                  value: '3、策划与用户互动的活动；'
                },
                {
                  value: '4、收集分析运营数据并运用到运营中。'
                }
              ]
            },
            {
              title: '任职要求',
              contentList: [
                {
                  value: '1、对公众号、视频号、抖音的平台规则熟悉；'
                },
                {
                  value: '2、熟练使用电脑工具创作内容；'
                },
                {
                  value: '3、有新媒体运营经验、出过爆文的优先；'
                },
                {
                  value: '4、应届生也可。'
                }
              ]
            }
          ]
        }
      ],
      // 福利待遇
      imgsList: [
        {
          img: require('../assets/lifanti.png'),
          content: '社会保险：根据国家有关部门规定，公司为所有员工按月度缴纳社会保险（包括养老保险、医疗保险、工伤保险、失业保险与生育保险）。'
        },
        {
          img: require('../assets/lifanti.png'),
          content: '丰富的文娱活动、竞赛及企业文化（部门拓展、聚餐、k歌、游戏竞赛等活动/员工生日礼物/丰富的体育项目活动,加强员工的身体素质/演讲、歌唱、体育运动等比赛，提升员工综合能力/高温补贴/传统节日的物资发放及节日慰问等等）'
        },
        {
          img: require('../assets/lifanti.png'),
          content: '公司提供带薪年假5天、法定节假日'
        },
        {
          img: require('../assets/lifanti.png'),
          content: '节日福利、不定期聚餐'
        },
        {
          img: require('../assets/lifanti.png'),
          content: '定期员工体检：关心员工身体健康，每年组织员工免费体检'
        },
        {
          img: require('../assets/lifanti.png'),
          content: '年度旅游：公司每年为全体员工提供一次户外旅游或拓展活动。 '
        },
        {
          img: require('../assets/lifanti.png'),
          content: '年底年终奖和带薪年假。'
        },
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.equipment = flag ? false : true;
    })
  },
  mounted: function () {
    window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    // 显示回到顶部按钮
    showbtn () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 1 && this.suspensionList.length == 0) {
        this.suspensionList.push({
          url: require("../assets/tableBtn/huojian.png"),
          title: "返回顶部"
        })
      } else if (scrollTop === 0) {
        this.suspensionList.pop();
      }
    },
    goTo () {
      window.open("https://mall.qinlin.plus");
    },
  }
}
</script>